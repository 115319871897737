@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.categoryGrid {
  background: $mediumBlue;

  &.light {
    background: $lightGrey;
    .categoryGrid__text {
      .title {
        color: $darkblue !important;
      }
    }
  }

  &__container {
    @include eq-mobile {
      padding: 40px 20px;
    }
    @include eq-tablet {
      padding: 40px 20px;
    }
    @include at-desktop {
      padding: 80px 40px;
    }
  }

  &__wrapper {
    margin-top: 40px;
    width: 100%;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
  }

  &__text {
    margin-bottom: 40px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: block;
    text-align: center;
    .title {
      color: $lightGrey !important;
    }
    .paragraph {
      color: $lightGrey !important;
    }
  }

  &__cta {
    text-align: center;
    margin-top: 30px;
  }

  .categoryCard {
    @include eq-mobile {
      width: calc(50% - 10px);
      margin-bottom: 10px;
      margin-left: 5px;
      margin-right: 5px;
    }
    @include eq-tablet {
      width: calc(33.3333% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 30px;
    }
    @include at-desktop {
      width: calc(25% - 20px);
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 30px;
    }
  }
}
