.pdf-viewer{
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
    width: calc(100% - 2%);
    max-width: 1110px;
    margin-top: calc(var(--component-height) / -2);
    margin-bottom: 4em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    font-size: 19px;
}

.react-pdf__Document {
    border-radius: 8px;
    max-width: 100%;
    position: relative;
    background-color: white;
  }


.react-pdf__Document:hover .page-controls {
    opacity: 1;
  }

.page-controls {
    position: absolute;
    bottom: 1%;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
    border-radius: 4px;
}

.page-controls span {
    font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
  }
  .page-controls button {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
  }
  .page-controls button:enabled:hover {
    cursor: pointer;
  }
  .page-controls button:enabled:hover,
  .page-controls button:enabled:focus {
    background-color: #e6e6e6;
  }
  .page-controls button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .page-controls button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  /*
  .react-pdf__Page, 
  .react-pdf__Page__svg ,
  .react-pdf__Page__svg svg
  {
    width: 100% !important;
  }

  .react-pdf__Page__svg {
    min-height: 100vh ! important;
    max-width: 100vw! important;
  }*/