@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.authorsPage {
  &__container {
    padding: 40px 20px;
  }

  &__list {
    
    list-style-type: none;
    margin: 0 !important;
    padding: 0 !important;

    // @include eq-mobile {
    //   column-count: 2;
    // }

    @include at-tablet {
      column-count: 3
    }
  }

  &__list-item {
    margin-bottom: 10px;
    @include eq-mobile
    {
      text-align: center;
    }
    a {
      position: relative;
      text-decoration: none;
      color: $dark;
      font-weight: 500;
      padding: 0 10px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: $dark;
        transition: width 0.25s ease-in-out;
      }

      &:hover {
        text-decoration: none;
        color: $dark;
        &::after {
          width: 100%;
        }
      }
    }
  }
}
