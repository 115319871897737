@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.categoryCard {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.25s ease-in-out;

  @include flexbox();
  @include align-items(center);

  @include eq-mobile {
    padding: 45px 20px;
  }

  @include at-tablet {
    padding: 20px;
    min-height: 200px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 3%;
    left: 3%;
    width: 94%;
    height: 94%;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    .categoryCard__image {
      img {
        transform: scale(1.1);
      }
    }
    &::after {
      border: 2px solid rgba(255, 255, 255, 1);
    }
  }

  &__image {
    position: absolute;
    @include size(100%);
    top: 0;
    left: 0;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        360deg,
        rgba(23, 50, 77, 0.9) 40%,
        rgba(23, 50, 77, 0.2) 100%
      );
      top: 0;
      left: 0;
      opacity: 0.95;
    }

    img {
      @include size(100%);
      object-fit: cover;
      transition: transform 0.25s ease-in-out;
    }
  }

  &__content {
    position: relative;
    font-weight: 500;
    color: $white;

    @include eq-mobile {
      font-size: em(16px);
      line-height: 1.2;
    }

    @include at-tablet {
      font-size: em(22px);
      line-height: em(24px, 22px);
    }
  }
}
