@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.booksList {
  background-color: $lightGrey;
  &__container {
    width: 100%;
    margin: 0;
    @include eq-mobile {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    @include at-tablet {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  &__header {
    @include eq-mobile {
      margin-bottom: 40px;
      padding: 0 20px;
      text-align: center;
    }
    @include at-tablet {
      margin-bottom: 40px;
      padding: 0 30px;
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(center);
      @include justify-content(space-between);
    }
  }

  &__title {
    color: $darkblue !important;
    text-align: center;
    opacity: 0.9;
  }

  &__cta {
    color: $dark !important;
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: none;
      .icon {
        transform: translateX(5px);
      }
    }
    span {
      margin-right: 10px;
    }
    .icon {
      transition: transform 0.25s ease-in-out;
    }
  }

  &__wrapper {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);

    @include eq-mobile {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include at-desktop {
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  &__notfound {
    background: red;
  }
}
