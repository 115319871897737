@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.booksSlider {
  position: relative;
  background-color: $lightGrey;
  &__container {
    @include eq-mobile {
      padding: 20px 0 20px 20px;
    }
    @include eq-tablet {
      padding: 40px 20px;
    }
    @include at-desktop {
      padding: 80px;
    }
  }

  &__title {
    margin-bottom: 40px;
    @include eq-mobile {
      text-align: center;
    }
    @include at-tablet {
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
    }

    .title {
      color: $darkblue !important;
    }
  }

  .bookCard {
    display: block;
    width: 100%;
    margin: 0 !important;
  }
}
