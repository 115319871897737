@import "../../../../scss/helpers/resolutions";
@import "../../../../scss//common/colors";
@import "../../../../scss/helpers/mixins";

.buttonPrimary {
  background-color: $darkblue;
  padding: 10px 30px;
  border-radius: 30px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid $darkblue;
  transition: box-shadow 0.25s ease-in-out, border-color 0.25s ease-in-out;

  &.disable {
    opacity: 0.5;
  }

  // @include flexbox();
  // @include align-items(center);
  // @include justify-content(center);

  a,
  span {
    text-decoration: none;
    color: $white;
    font-weight: 400;
  }

  &.yellow {
    background-color: $yellow;
    border: 1px solid $yellow;
    a,
    span {
      color: $darkblue;
    }

    &:hover {
      a,
      span {
        color: $darkblue;
      }
    }
  }

  &:hover {
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-color: $white;
    a,
    span {
      color: $white;
      text-decoration: none;
    }
  }
}
