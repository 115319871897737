#notices-section {
   margin-top: 40px;
   margin-bottom: 60px;
   padding-left: 20px;
   padding-right: 20px;
}

.notice-box-wrapper {
   position: relative;
   overflow: hidden;
   margin-bottom: 15px;
}

.notice-box{
   position: relative; 
   width: 100%; /* for IE 6 */
   border-radius: 5px;
   display: block;
   overflow: hidden;
}

.notice-box:hover img {
   transform: scale(1.1);
}

.notice-box img {
   position: relative;
   transition: transform 0.5s ease-in-out;
   z-index: 1;
   opacity: 0.75;
}

.notice-box::after {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* background-color: #000000; */
   background-color: #ffffff;
}

/* new card */
.notice-box-content {
   position: absolute;
   bottom: 30px;
   width: calc(100% - 20px);
   left: 10px;
   z-index: 1;
   font-size: 16px;
}

.notice-box-content .notice-box-title {
   color: #ffffff !important; 
   font-size: 1.375em !important;
   /* font: bold 19px Helvetica, Sans-Serif;  */
   letter-spacing: -1px;  
   text-transform: uppercase;
   margin: 0 0 5px !important;
}

.notice-box-content .notice-box-title span {
   /* background: rgb(0, 0, 0);
   background: rgba(0, 0, 0, 0.6); */
   background: #17324D;
   padding: 8px 10px; 
   display: inline-block;
   transition: all 0.5s ease-in-out; 
}

.notice-box:hover .notice-box-title span {
   background: #ffffff;
   color: #17324d;
   box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.notice-box-content .notice-box-subtitle {
   color: #000000 !important;
   font-size: 1em !important;
   margin: 0 !important;
}

.notice-box-content .notice-box-subtitle span {
   /* background: rgb(255, 255, 255);
   background: rgba(255, 255, 255, 0.85); */
   background-color: #ffffff;
   padding: 5px 10px; 
   display: inline-block;
}
/* ./end new card */

h2.notice-text{
    position: absolute; 
    /* top: -40px;  */
    bottom: 35px;
    left: 0; 
    width: 100%;
    padding: 0 10px; 
    z-index: 1;
}

span.notice-text { 
    color: white; 
    font: bold 19px Helvetica, Sans-Serif; 
    letter-spacing: -1px;  
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.7);
    padding: 10px; 
    margin: 0;
 }

 span.notice-text-small{
    color: white; 
    font: bold 14px Helvetica, Sans-Serif;    
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.7);
    padding: 10px; 
    margin: 0;
 }

 .notice-box-gray{
    background: rgb(240, 239, 239);
    text-align: center;  
    margin-bottom: 5px;
 }

 .notice-box-green{
  /*  background: rgba(105, 162, 175, 0.856); */
  background: rgba(128,174,184, 0.856);
    text-align: center;
    fill: white;  
    margin-bottom: 5px;
 }

 .notice-box-green-2{
    background: rgba(11, 95, 112, 0.842);
    text-align: center;
    fill: white;    
 }

 .notice-link{
    color: #19191a;
    text-decoration: none;
 }

 .notice-link:hover{ 
    text-decoration: none;
 }


 .notice-link-2{
    color: white;
    text-decoration: none;
 }

 .notice-link-2:hover{ 
    text-decoration: none;
 }

 @media only screen and (max-width: 576px) {

   .notice-box-image{
      padding-bottom: 10px;
      width: 30%;
   }

   .notice-box-gray{     
      padding: 30px 10px;    
   }
  
   .notice-box-green{
      padding: 30px 10px;    
   }
  
   .notice-box-green-2{
      padding: 30px 10px;    
   }
}

@media only screen and (min-width: 577px) {

   .notice-box-image{
      padding-bottom: 40px;
      width: 20%;
   }

   .notice-box-gray{     
      padding: 80px 20px;
      min-height: 350px;  
   }
  
   .notice-box-green{
      padding: 50px 20px;
      min-height: 350px; 
   }
  
   .notice-box-green-2{
      padding: 50px 20px;
      min-height: 350px; 
   }
}