
table.MTable {
  width: 99%;
  padding: 1rem;
  border-spacing: 0;
}

table.MTable th, table.MTable td {
  margin: 0;
  padding: 0.5rem;
  /* The secret sauce */
  /* Each cell should grow equally */
  /*width: 1%;*/
}

@media only screen and (max-width: 640px) {
  table.MTable th, table.MTable td {
    width: 1%;
  }
}

.MTable-actions .action {
  cursor: pointer;
}

.MTable-actions{
  width: 48px !important;
}
.MTable-actions .action > .icon{
    width: 30px !important;
}

.pagination {
  padding: 0.5rem;
}