@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.pageListBooks {
  display: block;
  overflow: visible;
  position: relative;
  background: $white;

  &__container {
    margin-top: 40px;
    margin-bottom: 40px;
    @include at-desktop {
      @include flexbox();
      @include justify-content(space-between);
    }
  }
  &__filters {
    background-color: $white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    @include eq-mobile {
      padding-top: 20px;
      padding-bottom: 20px;
      top: 60px;
      z-index: 99;
    }
    @include pre-desktop {
      position: sticky;
      // top: 100px;
      width: 100%;
    }
    @include at-desktop {
      position: sticky;
      top: 0px;
      width: 30%;
      height: 100%;
      z-index: 99;
      display: block;
      padding-top: 120px;
    }

    &__items {
      position: relative;

      // @include eq-mobile {
      //   padding-top: 20px;
      // }

      @include pre-desktop {
        width: 100%;
        height: 0;
        overflow: hidden;
        // opacity: 0;
        // visibility: hidden;
        background: $white;
        // transform: translateY(-100%);
        // transition: transform 0.5s ease-in-out;
        // padding: 20px;
      }
      @include at-desktop {
        padding: 0 20px 20px;
      }

      &.open {
        @include pre-desktop {
          visibility: visible;
          height: 100vh;
          overflow: auto;
          // transform: translateY(0);
          transition: transform 0.5s ease-in-out;
          // opacity: 1;
        }
      }
    }

    &__title {
      padding: 0 20px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: em(14px);
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);

      .icon-filter {
        margin-right: 10px;
      }

      .icon-arrow {
        margin-left: 10px;
        transition: transform 0.25s ease-in-out;
        @include at-desktop {
          display: none;
        }
      }

      &.open {
        .icon-arrow {
          transform: rotate(180deg);
        }
      }
    }
    &__reset {
      // position: absolute;
      // top: -28px;
      // right: 20px;
      // display: none;
      cursor: pointer;
    }
    &__item {
      width: 100%;
      padding: 20px 0 10px;

      @include pre-desktop {
        padding: 20px;
      }
    }
  }
  &__content {
    @include at-desktop {
      width: 70%;
    }
  }
  .booksList {
    .bookCard {
      @include at-desktop {
        width: calc(33.3333% - 40px);
      }
    }
  }
}
