textarea:disabled, textarea[readonly] {
  cursor: not-allowed;
  background-color: #e6e9f2;
  border: none !important;
  border-bottom: 1px solid #5c6f82 !important;
}

textarea {
 
  border-radius: 0;
  padding: .375rem .5rem;
  outline: 0;
  width: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: .2s ease-out;
  transition: .2s ease-out;
  font-weight: 700;
  opacity: 1;
  background-color: #fff;
  color: #17324d;
  font-size: 14px;
  text-decoration: none;
}

textarea:disabled, .form-control[readonly] {
  cursor: not-allowed;
}

textarea:hover {
  box-shadow: 0 1px 0px #465158;
}

textarea {
  background-position: 98% 10% !important;
  background-repeat: no-repeat !important;
  background-size: 45px 10% !important;
}

textarea.is-valid {
  border-color: #008758;
  padding-right: calc(1.5em + .75rem);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300cc85' viewBox='0 0 192 512'%3E%3Cpath d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
}

textarea.is-invalid {
  padding-right: calc(1.5em + .75rem);
  border-color: #d9364f;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f73e5a' viewBox='0 0 384 512'%3E%3Cpath d='M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z'/%3E%3C/svg%3E")
}