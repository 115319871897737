@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.categoryPage {
  &__container {
    // padding-top: 40px;
    padding-bottom: 40px;
  }

  &__cover {
    position: relative;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    @include flex-direction(column);

    @include eq-mobile {
      min-height: 200px;
    }
    @include at-tablet {
      min-height: 280px;
    }
  }

  &__cover__image {
    position: absolute;
    width: 100%;
    height: 100%;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__category-title {
    position: relative;
    padding: 20px;
    z-index: 1;
    text-align: center;
    .title {
      color: $white !important;
      letter-spacing: 0.5px;
    }
  }

  &__category-description {
    position: relative;
    padding: 20px;
    margin-top: 40px;
    text-align: center;
  }
}
