@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.container_solidarity {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video_solidarity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pageSolidarity {
  background: $lightGrey;
  padding-top: 80px;
  padding-bottom: 80px;

  &__wrapper {
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__title {
    .title {
      color: $darkblue !important;
    }
    margin-bottom: 20px;
  }

  &__paragraph {
    @include at-desktop {
      width: 70%;
    }
    p {
      color: $dark;
    }
    margin-bottom: 40px;
  }

  &__list-items {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(space-between);
  }

  &__list-item {
    @include eq-mobile {
      width: 100%;
      margin-bottom: 20px;
    }
    @include at-tablet {
      width: calc(50% - 20px);
      margin-bottom: 40px;
    }
    display: inline-block;
  }
}
