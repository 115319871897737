@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.heroBiblioteca {
  width: 100%;
  position: relative;
  overflow: hidden;
  @include flexbox();
  @include flex-direction(column);
  @include justify-content(center);

  @include at-desktop {
    min-height: 480px;
  }

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $darkblue;
      opacity: 0.9;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    z-index: 1;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    color: $white;

    @include eq-tablet {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include eq-mobile {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .logo {
      margin-bottom: 20px;
      img {
        display: block;
        max-width: 280px;
        width: 100%;
        height: auto;
        margin: 0 auto;

        @include eq-mobile {
          max-width: 180px;
        }
      }
    }
  }

  &__title {
    color: $lightGrey;
    opacity: 0.85;
    margin-bottom: 20px;
    color: $white !important;

    @include eq-mobile {
    }

    @include at-desktop {
      font-size: em(48px) !important;
      line-height: em(52px, 48px) !important;
    }
  }

  &__subtitle {
    color: $lightGrey;
    @include at-desktop {
      font-size: em(18px) !important;
      line-height: em(22px, 18px) !important;
    }
  }
}

.introBiblioteca {
  position: relative;
  width: 100%;
  background-color: $lightGrey;

  &__wrapper {
    @include eq-mobile {
      padding: 40px 20px;
    }
    @include at-tablet {
      max-width: 940px;
      padding: 80px 40px;
      margin: 0 auto;
    }
  }

  &__buttons {
    @include at-tablet {
      @include flexbox();
      @include flex-wrap(wrap);
    }
    &--login {
      @include eq-mobile {
        margin-bottom: 20px;
      }
      @include at-tablet {
        margin-right: 20px;
      }
    }
  }
}
