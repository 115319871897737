@import '../../scss/helpers/resolutions';
@import '../../scss//common/colors';
@import '../../scss/helpers/mixins';

.cardNews
{
    position: relative;
    margin-bottom: 10px;
    display: block;
    overflow: hidden;    
    height: calc(100% - 10px);     
    border-right: var(--border,1px solid #cdcdd6);
    padding-right: 20px;

    &__link
    {
        text-decoration: none;
        color: $darkblue;
    }

    &__link:hover
    {
        text-decoration: none;
        color: $mediumBlue;
    }

    &__link_header, &__link_header:hover
    {
        text-decoration: none;
        color: $mediumBlue;
    }

    &__image
    {
        position: relative;
        width: 100%;
        height: 150px;
        overflow: hidden;
        img
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left center;
        }
    }

    &__listItem{      
            font-size: 1rem !important;
            line-height: 2rem !important;
            font-weight: 400;
            margin: 0px;        
    }

    &__content
    {
        
        position: relative;
        height: 100%;
        z-index: 1;        
        p
        {
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 10px;
        }

    }

    &__header
    {
        font-size: 0.7rem;
        font-weight: 700;
        color: $mediumBlue;
        padding: 8px 0;
    }

    &__footer
    {
        font-size: 0.7rem;
        font-weight: 600;
        color: $darkGrey;
    }

    &__action
    {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}