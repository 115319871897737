@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.bookCard {
  position: relative;
  background: $darkblue;
  border-radius: 20px;
  text-decoration: none;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    text-decoration: none;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    .bookCard__button {
      .icon {
        transform: translateX(5px);
      }
    }
  }

  @include eq-mobile {
    width: 100%;
    margin-bottom: 20px;
  }
  @include eq-tablet {
    width: calc(33.3333% - 40px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  @include at-desktop {
    width: calc(25% - 40px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  &__content {
    padding: 20px 20px 30px;
    display: block;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &__wishlist {
    // background: red;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 9;
    cursor: pointer;
    // @include flexbox();
    // @include align-items(center);
    // @include justify-content(center);

    svg {
      width: 100%;
      height: 100%;
      //   object-position: center center;
      object-fit: cover;
    }
  }

  &__category {
    color: $yellow;
    font-size: em(10px);
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 1.1px;
    margin: 0 0 10px;
    opacity: 0.9;
  }

  &__title {
    border-top: 1px solid $lightGrey;
    border-bottom: 1px solid $lightGrey;
    min-height: 95px;

    .title {
      color: $lightGrey !important;
      font-size: em(14px) !important;
      margin: 0 !important;
      padding: 8px 0 20px;
      font-weight: 600;
      opacity: 0.8;
    }
  }

  &__author {
    .author {
      color: $white !important;
      font-size: em(12px);
      opacity: 0.8;
      margin-top: 5px;
    }
  }

  &__button {
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: $lightGrey !important;
    text-decoration: none;
    z-index: 1;
    font-size: em(12px);
    line-height: 1;
    margin: 0;
    opacity: 0.8;
    letter-spacing: 0.7px;

    .icon {
      transition: transform 0.25s ease-in-out;
      width: 20px;
      height: auto;
      margin-left: 5px;
    }
  }
}
