@import '../../scss/helpers/resolutions';
@import '../../scss//common/colors';
@import '../../scss/helpers/mixins';

.corrispondenza-titoli
{

    background: $lightGrey;

    &__wrapper
    {
        text-align: center;
        padding: 20px 20px;
    }
}
