    .steppers .steppers-content {
        padding: 10px 15px;
        margin: 5px 0;
        align-items: center;
    }

    @media (max-width: 992px) {
        .steppers .steppers-content {
            background: white;
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);            
        }
    }