/*** COMPOSER QUIZ ***/
.header-answer-card-wrapper > .card-wrapper{
    height: initial !important;
}
.header-answer-index > .card-body{
    padding: 10px 15px !important;
}
.header-answer-index > .card-body > h1{
    font-size: 1.60em !important;
    letter-spacing: 0.025em !important;
    margin-bottom: 0px !important;
}


.header-quiz-composer{
    /*border-bottom: 4px !important;*/
}
.header-quiz-composer .card-wrapper{
    width: 100% !important;
    margin-top: -25px !important;
}
.header-quiz-composer .card-wrapper .card-body{
    padding: 10px 12px !important;
}
.header-quiz-composer .card-wrapper h1{
    font-size: 1.20em !important;
    letter-spacing: 0.025em !important;
    margin-bottom: 0px !important;
    color: #ffffff;
}

.questionComposerSection .card{
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}
.questionComposerSection .card-body{
    padding: 28px 36px !important;
}
.questionComposerSection .card-wrapper:nth-child(odd) .card div.card-body{
    /*background-color: lawngreen !important;*/
}
.questionComposerSection .card-wrapper:nth-child(even) .card div.card-body{
    background-color: #f4f4f4 !important;
}
.questionComposerSection .card-body .form-group{
    padding: 8px 0px !important;
    margin: 8px 0px !important;
}


/*** LESSON FORM in lesson-form ***/
.resourceType{
    width: 100%;
}
.resourceType fieldset{
    margin-bottom: 10px !important;
}
.resourceType legend{
    font-size: 18px !important;
    font-weight: 600;
    font-family: inherit;
    margin-bottom:16px;
}
.resourceType .form-check-group{    
    width: calc(33.3333% - 1%);
    margin: 0 0.5%;
    display: inline-block !important;    
}
.resourceType .small,
.resourceType small{
    display: block !important;
    margin-bottom: 20px !important;
}
/*
.nav-link{
   border-bottom: solid 1px #06c!important;
}*/

.answerWrapper .row{
    /*width: calc(50% - 12px);*/
    margin: 6px;
    margin-top: 0px;
    border-left: 4px solid #06c !important;

    -moz-transition: all 0.05s ease-out;
    -o-transition: all 0.05s ease-out;
    -webkit-transition: all 0.05s ease-out;
    transition: all 0.05s ease-out;
}

.answerWrapper .row.border-0{
    /*width: calc(50% - 12px);*/
    border: 0px !important;
}

/*** ANSWERS INDEX in course-form ***/
.container-card-body-ListAnswer{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.container-card-body-ListAnswer .card-body{
    padding: 14px 12px !important
}
.container-card-body-ListAnswer .card-body tbody td[role="cell"]{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 188px;
}
.header-lessons-card-wrapper > .card-wrapper{
    height: initial !important;
}
.header-lessons-index > .card-body{
    padding: 10px 15px !important;
}
.header-lessons-index > .card-body > h1{
    font-size: 1.60em !important;
    letter-spacing: 0.025em !important;
    margin-bottom: 0px !important;
}



@media (max-width: 1024px) {
    .answerWrapper .row{
        width: 100%;
    
        -moz-transition: all 0.05s ease-out;
        -o-transition: all 0.05s ease-out;
        -webkit-transition: all 0.05s ease-out;
        transition: all 0.05s ease-out;
    }
}


/** ANIMATION MANGE QUIZ ON LESSON */
.card-body-lesson-form{
    padding: 48px 24px !important;

    -moz-transition: all 0.05s ease-out;
    -o-transition: all 0.05s ease-out;
    -webkit-transition: all 0.05s ease-out;
    transition: all 0.05s ease-out;
}

.card-body-lesson-form .w50perc{
    width: 50%;

    -moz-transition: all 0.05s ease-out;
    -o-transition: all 0.05s ease-out;
    -webkit-transition: all 0.05s ease-out;
    transition: all 0.05s ease-out;
}
/** ANIMATION MANGE QUIZ ON LESSON */