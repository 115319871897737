/*** LESSONS INDEX in course-form ***/
.container-card-body-ListLesson{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.container-card-body-ListLesson .card-body{
    padding: 14px 12px !important
}
.container-card-body-ListLesson .card-body tbody td[role="cell"]{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 188px;
}
.header-lessons-card-wrapper > .card-wrapper{
    height: initial !important;
}
.header-lessons-index > .card-body{
    padding: 10px 15px !important;
}
.header-lessons-index > .card-body > h1{
    font-size: 1.60em !important;
    letter-spacing: 0.025em !important;
    margin-bottom: 0px !important;
}