@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.cardSolidarity {
  background: $darkblue;
  cursor: default;
  pointer-events: none;
  display: block;
  width: 100%;
  text-decoration: none;
  border: 1px solid $grey;
  overflow: hidden;
  transition: box-shadow 0.5s ease-in-out;

  &.link {
    cursor: pointer;
    pointer-events: initial;
    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      .cardSolidarity__image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
  &__image {
    position: relative;
    width: 100%;
    background: $lightGrey;
    min-height: 300px;
    display: block;
    overflow: hidden;

    img {
      position: absolute;
      object-fit: contain;
      width: 100%;
      height: 100%;
      display: block;
      transition: transform 0.5s ease-in-out;
    }
  }

  &__content {
    position: relative;
    padding: 20px 20px 80px;
    @include at-tablet {
      min-height: 220px;
    }
    .title {
      color: $lightGrey !important;
      margin: 0;
    }
    .subtitle {
      color: $lightGrey !important;
    }
  }
  &__cta {
    position: absolute;
    bottom: 20px;
    right: 20px;
    @include flexbox();
    @include justify-content(flex-end);
  }
}
