@import "../../scss/helpers/resolutions";
@import "../../scss//common/colors";
@import "../../scss/helpers/mixins";

.advModal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    top: 0;
    left: 0;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
}

.advModal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.advModal__wrapper {
    position: relative;
    // width: 100%;
    // height: 100%;
    max-width: 460px;
    width: 100%;
    margin: 0 auto;

    @include eq-mobile {
        width: calc(100% - 40px);
    }

    img {
        width: 100%;
    }
}

.advModal__content {
    position: relative;
    background: #ffffff;
    // padding: 20px;
    border-radius: 10px;
}

.advModal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}