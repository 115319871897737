@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.navLibrary {
  position: sticky;
  background: $darkblue;
  margin: 0;
  z-index: 999;

  @include eq-mobile {
    top: 60px;
  }
  @include eq-tablet {
    top: 0px;
    padding-top: 120px;
  }
  @include at-desktop {
    top: 0px;
    padding-top: 120px;
  }

  &::before {
    content: "";
    position: absolute;
    background-color: $darkblue;
    width: 100vw;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  &__wrapper {
    @include eq-mobile {
      padding: 20px;
    }
    @include at-tablet {
      padding: 0 20px 20px;
    }
  }

  .menuLibrary {
    &__logo {
      @include eq-mobile {
        display: none;
      }
      position: absolute;
      top: 30px;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
    }
    &__items {
      position: relative;
      z-index: 1;
      margin: 0 !important;
      padding: 0 !important;
      list-style-type: none;
      @include flexbox();
      @include justify-content(center);
      @include flex-wrap(wrap);
      // @include at-desktop {
      // }
    }

    &__item {
      display: block;
      margin-left: 30px;
      font-weight: 500;
      &:first-child {
        margin-left: 0;
      }

      @include eq-mobile
      {
        margin-left: 12px;
      }

      a {
        position: relative;
        text-decoration: none;
        color: $lightGrey;
        transition: color 0.25s ease-in-out;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 1px;
          background: $yellow;
          transition: width 0.25s ease-in-out;
        }

        &:hover {
          color: $yellow;
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
