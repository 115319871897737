@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.bookCover {
  position: relative;
  background-color: $darkblue;
  // margin-top: 40px;
  // margin-bottom: 40px;
  // border-radius: 20px;

  &__container {
    width: 100%;
    margin: 0 auto;
    @include eq-mobile {
      padding: 40px 20px;
    }
  }

  &__wrapper {
    @include at-tablet {
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(space-between);
    }
  }

  &__column {
    @include at-tablet {
      width: 50%;
      @include flexbox();
      @include justify-content(center);
    }

    &.title {
      @include eq-mobile {
        @include align-items(center);
        padding: 0 0 20px;
      }

      @include eq-tablet {
        @include align-items(center);
        padding: 40px;
      }
      @include at-desktop {
        @include align-items(center);
        padding: 60px;
      }
    }
    &.content {
      @include eq-tablet {
        padding: 40px;
      }
      @include at-desktop {
        padding: 60px 40px;
      }
      @include flexbox();
      @include flex-wrap(wrap);
      @include flex-direction(row);
    }

    .content__item {
      border-bottom: 1px solid $lightGrey;
      width: 100%;
      margin-bottom: 40px;

      &.half {
        @include at-tablet {
          width: 50%;
        }
      }

      .label {
        color: $yellow;
        font-size: em(12px);
        text-transform: uppercase;
        font-weight: 500;
        margin: 0;
      }

      .text {
        color: $white;
        font-size: em(16px);
        margin-top: 10px;

        .author-link {
          text-decoration: none;
          color: $white;
          transition: color 0.25s ease-in-out;

          &:hover {
            color: $yellow;
          }
        }
      }
    }

    .buttons-actions {
      position: relative;
      z-index: 9;
      width: 100%;
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(flex-start);
    }

    .login-to-download {
      // background-color: $yellow;
      padding: 10px 30px;
      border-radius: 30px;
      cursor: pointer;
      display: inline-block;
      border: 1px solid $yellow;
      color: $yellow;
      font-weight: 400;
      opacity: 0.6;
      text-decoration: none;
    }

    .book-download {
      background-color: $yellow;
      text-decoration: none;
      padding: 10px 30px;
      border-radius: 30px;
      cursor: pointer;
      display: inline-block;
      border: 1px solid $yellow;
      color: $darkblue;
      font-weight: 400;
      transition: box-shadow 0.25s ease-in-out, border-color 0.25s ease-in-out;

      &:hover {
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
        border-color: $white;
      }
    }

    .book-title {
      color: $lightGrey !important;
      @include eq-mobile {
        font-size: em(24px) !important;
        letter-spacing: 0.8px;
      }
    }
  }
}
