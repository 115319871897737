.articles{
    padding: 5px;
}

.articleitem-card {   
    width: 98%;    
    border-radius: 4px;
    margin: auto;
    margin-bottom: 20px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;  
    overflow: hidden;
    cursor: pointer;   
}

.articleitem-card__header {   
    width: 45%;       
    position: relative;
    overflow: hidden;
}

.articleitem-card__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;   
}

.articleitem-card__body {  
    min-height: 170px;  
    width: 100%;
    padding: 15px;
}

.articleitem-card__body_home {      
    width: 100%;
    padding: 15px;
}

@media only screen and (max-width: 576px) {

   
}

@media only screen and (min-width: 577px) {

    .articleitem-card__body_home {  
        min-height: 200px;          
    }
}

.articleitem-card__copy {
    color: #435a70;
    font-size: .77778rem;   
    font-family: 'Poppins' !important;
    font-weight: 200 !important;
    text-align: justify;   
    line-height: 1.2rem;
}

.articleitem-card__footer {
    color: #1F5C65 ;
    font-size: .77778rem;   
    font-family: 'Poppins' !important;
    font-weight: 200 !important;    
}

.articleitem-card__link{
    text-decoration: none !important;
}

.social-share-container
{
    margin-top: 40px;
    margin-bottom: 40px;
}

.social-share-container button
{
    margin-right: 10px;
}