//styles dependency
@import "../../scss/helpers/resolutions";
@import "../../scss//common/colors";
@import "../../scss/helpers/mixins";

// headroom
.headroom-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $mediumBlue;
  z-index: 9999;
  height: auto !important;

  .headroom {
    z-index: 9;
    // position: fixed !important;
    // position: relative !important;
    transition: box-shadow 0.5s ease-in-out;
  }

  .headroom--unpinned {
    transition: transform 0.5s ease-in-out;

    @include eq-mobile {
      // height: 80px;
      transform: translateY(0px) !important;
    }
    @include at-tablet {
      // height: 80px;
      transform: translateY(-40px) !important;
    }
  }

  .mainHeader__content {
    background: $mediumBlue;
  }
}

// Header site
.mainHeader {
  position: relative;
  background-color: $mediumBlue;
  //wrapper mainHeader
  &__wrapper {
    position: relative;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  //header top
  &__top {
    @include eq-mobile {
      display: none;
    }

    @include at-tablet {
      @include flexbox();
      @include flex-wrap(wrap);
      @include justify-content(flex-end);
      @include align-items(center);
      min-height: 40px;
      position: relative;
      width: 100%;
      background-color: $lightBlue;
    }

    &__links {
      display: block;
      @include flexbox();
      @include justify-content(flex-end);
      color: $white;

      .link {
        @include flexbox();
        position: relative;
        font-weight: 500;
        color: $white;
        text-decoration: none;
        padding-left: 20px;
        padding-right: 20px;
        transition: color 0.25s ease-in-out;

        &:hover {
          color: $darkblue;
        }

        &::after {
          content: "";
          background-color: $lightGrey;
          height: 100%;
          width: 1px;
          position: absolute;
          top: 0;
          right: 0px;
          opacity: 0.5;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }

        .text {
          display: inline-block;
          font-size: em(12px);
        }
      }
    }
  }

  &__content {
    @include eq-mobile {
      // height: 100px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include at-tablet {
      // height: 100px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &__wrapper {
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);
      position: relative;
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__logo {
      position: relative;
      height: auto;
      transition: all 0.5s ease-in-out;

      @include eq-mobile {
        width: 40%;
      }

      @include eq-tablet {
        width: 180px;
      }
      @include at-desktop {
        width: 240px;
      }

      .logo_site {
        display: block;
        width: 100%;
        height: auto;

        @include eq-mobile {
          max-width: 180px;
        }
      }
    }

    &__navigation {
      @include flexbox();
      @include flex-wrap(wrap);
      @include align-items(center);

      @include eq-mobile {
        width: 60%;
        @include flexbox();
        @include justify-content(flex-end);
        @include align-items(center);
        @include flex-wrap(wrap);
      }
    }
  }
}

// headroom unpinned scroll down
.headroom-wrapper {
  .headroom--unpinned {
    // position: relative;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    .mainHeader__content {
      @include at-tablet {
        // height: 80px;
        background: $mediumBlue;
      }

      &__logo {
        position: relative;
        height: auto;

        @include eq-mobile {
          width: 120px;
        }
        @include at-tablet {
          width: 160px;
        }
      }
    }
  }

  .headroom--pinned {
    // position: relative;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    .mainHeader__content {
      background: $mediumBlue;
    }
  }

  &.bannerTop {
    .headroom--unpinned {
      transition: transform 0.5s ease-in-out;

      @include eq-mobile {
        // height: 80px;
        // transform: translateY(-80px) !important;
        transform: translateY(-50px) !important;
      }
      @include at-tablet {
        // height: 80px;
        transform: translateY(-100px) !important;
        // transform: translateY(-40px) !important;
      }
    }
  }

  .header__bannertop {
    width: 100%;
    background-color: $yellow;
    @include at-tablet {
      min-height: 70px;
      @include flexbox();
      @include align-items(center);
    }
    @include eq-mobile {
      min-height: 70px;
      @include flexbox();
      @include align-items(center);
    }
    &__wrapper {
      position: relative;
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      @include at-tablet {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &__content {
      position: relative;
      text-align: center;
    }
    &__close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-55%);
      // width: 24px;
      // height: 24px;
      z-index: 1;
      cursor: pointer;
    }
  }
}
// end Header site

// MAIN NAVIGATION TOGGLE
.mainNavigation {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;

  @include flexbox();
  @include justify-content(flex-end);

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s 0.5s ease-in-out;
  }

  &__wrapper {
    position: realative;
    width: 100%;
    height: 100%;
    max-width: 520px;
    background: $mediumBlue;
    z-index: 1;
    overflow-y: scroll;
    overflow-x: hidden;

    @include eq-mobile {
      padding: 0px 20px 100px;
    }

    @include eq-tablet {
      padding: 0px 60px 40px;
    }

    @include at-desktop {
      padding: 0px 60px 40px;
    }
  }

  &__closeButton {
    position: absolute;
    z-index: 999;
    background: $mediumBlue;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    @include pre-desktop {
      top: 20px;
      right: 20px;
    }

    @include at-desktop {
      cursor: pointer;
      top: 20px;
      right: 20px;
    }
    svg {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    // &__wrapper {
    //   position: relative;
    //   width: 100%;
    //   height: 100%;

    // }
  }

  &__content {
    position: relative;
    @include flexbox();
    @include flex-direction(column);
  }

  // Menu OPEN
  &.open {
    transform: translateX(0);

    .mainNavigation__overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}
