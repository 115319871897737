@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.slidesHome {
  @include at-desktop {
    margin-top: -38px;
  }
  &__wrapper {
    height: 100%;
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(center);
    @include at-desktop {
      min-height: 280px;
    }
  }

  &__image {
    img {
      // position: relative;
      // width: 100%;
      // height: auto;
      // display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    // &::after
    // {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: $dark;
    //     opacity: 0.5;
    // }

    .image__mobile {
      @include at-tablet {
        display: none;
      }
    }
    .image__desktop {
      @include eq-mobile {
        display: none;
      }
    }
  }

  &__content {
    position: relative;
    // top: 0;
    // left: 0;
    // height: 100%;
    z-index: 1;
    // @include flexbox();
    // @include flex-direction(column);
    // @include justify-content(center);

    @include eq-mobile {
      width: 100%;
      padding: 40px 20px 80px;
    }

    @include eq-tablet {
      width: 100%;
      padding: 40px 40px 80px;
    }

    @include at-desktop {
      width: 70%;
      padding: 20px 60px 40px;
    }

    &__logo {
      img {
        max-width: 180px;
        width: 100%;
        height: auto;
        margin-bottom: 10px;
        display: block;
      }
    }

    &__label {
      .slide__label {
        font-size: em(14px);
        line-height: em(14px, 14px);
        font-weight: 400;
        color: $white;
        text-transform: uppercase;
      }
    }

    &__title {
      .slide__title {
        font-size: em(36px) !important;
        line-height: em(36px, 36px) !important;
        color: $white !important;
        // color: $yellow !important;
        font-weight: 600 !important;
      }
    }

    &__subtitle {
      .slide__subtitle {
        font-size: em(20px);
        line-height: em(22px, 20px);
        color: $lightGrey;
      }
    }
  }

  .swiper-pagination {
    width: auto !important;

    @include eq-mobile {
      left: 20px !important;
      bottom: 20px !important;
    }

    @include eq-tablet {
      left: 40px !important;
      bottom: 20px !important;
    }

    @include at-desktop {
      left: 60px !important;
      bottom: 20px !important;
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 16px !important;
      height: 16px !important;
      border-radius: 50%;
      background: none !important;
      border: 1px solid $white;
      opacity: 1 !important;

      &.swiper-pagination-bullet-active {
        &::after {
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .swiper-slide {
    height: auto !important;
  }
}
