//styles dependency
@import "../../scss/helpers/resolutions";
@import "../../scss//common/colors";
@import "../../scss/helpers/mixins";

// container menu
.mainMenuContainer {
  position: relative;

  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(space-between);

  // navigation main header
  &__navigation {
    @include nav-compressed {
      display: none;
    }
    // container links
    &__links {
      @include flexbox();
      @include justify-content(space-between);
      list-style-type: none;
      margin: 0 20px 0 0;
      padding: 5px 0;

      // link
      .link-item {
        position: relative;
        margin-left: 15px;
        margin-right: 15px;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }

        // separatore
        &:not(:first-child)::before {
          content: "";
          position: absolute;
          left: -15px;
          height: 100%;
          width: 1px;
          background-color: $lightGrey;
          opacity: 0.25;
        }

        // animazione hover
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 0;
          background: $lightGrey;
          transition: width 0.5s ease-in-out;
        }

        &:hover {
          color: $white;

          &::after {
            width: 100%;
          }
        }

        // testo link
        &__text {
          position: relative;
          color: $white;
          text-decoration: none;
          font-weight: 400;
          text-transform: none;
          letter-spacing: 1.3px;
          font-size: em(16px);

          &.dropdown {
            padding-right: 20px;
            &:hover {
              .icon {
                img {
                  top: calc(50% + 4px);
                  // transform-origin: bottom left;
                  // top: 4px;
                }
              }
            }
          }
          .icon {
            position: relative;
            width: 20px;
            height: 20px;
            img {
              position: absolute;
              top: 50%;
              right: -20px;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              transition: all 0.5s ease-in-out;
            }
          }
        }

        .link-item__text.dropdown.open {
          .icon {
            img {
              transform: rotate(180deg);
              top: calc(50% - 10px);
            }
          }
        }
      }
    }
  }

  &__burger {
    position: relative;
    width: 40px;
    height: 40px;

    @include eq-mobile {
      margin-left: 10px;
    }

    @include eq-tablet {
      margin-left: 20px;
    }

    @include at-desktop {
      margin-left: 20px;
      cursor: pointer;
    }
    // @include flexbox();
    // @include flex-direction(column);
    // @include flex-wrap(wrap);
    // @include align-items(center);
    // @include justify-content(space-between);

    span {
      position: absolute;
      width: 28px;
      height: 2px;
      background-color: $white;
      left: 50%;
      transform: translateX(-50%);
      transition: transform 0.25s ease-in-out;

      &:nth-child(1) {
        top: calc(50% - 8px);
        transform: translate(-50%, -50%);
      }
      &:nth-child(2) {
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:nth-child(3) {
        top: calc(50% + 8px);
        transform: translate(-50%, -50%);
      }
    }

    &:hover {
      span {
        &:nth-child(1) {
          transform: translate(-8px, -50%);
        }
        &:nth-child(3) {
          transform: translate(-8px, -50%);
        }
      }
    }
  }

  &__user {
    background: $darkblue;
    padding: 5px 10px; 
    border-radius: 5px;
    transition: box-shadow 0.5s ease-in-out;
    &:hover
    {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
    .user {
      &__link {
        @include flexbox();
        @include align-items(center);
        color: $white;
        text-decoration: none;
      }
      &__icon {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        // width: 24px;
        // height: 24px;

        // svg
        // {
        //     width: 20px;
        //     height: 20px;
        // }
      }

      &__text {
        // margin-top: 5px;
        // padding-top: 3px;
        margin-left: 5px;

        @include eq-mobile {
          // span
          // {
          //     display: none;
          // }
        }
      }
    }
  }

  &__profile {
    cursor: pointer;
    background: $darkblue;
    padding: 5px 10px; 
    border-radius: 5px;
    transition: box-shadow 0.5s ease-in-out;
    &:hover
    {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    .profile {
      &__link {
        @include flexbox();
        @include align-items(center);
        color: $white;
        text-decoration: none;
        position: relative;
        z-index: 9;
      }
      &__icon {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        // width: 24px;
        // height: 24px;

        // svg
        // {
        //     width: 20px;
        //     height: 20px;
        // }
      }

      &__text {
        // margin-top: 5px;
        // padding-top: 3px;
        position: relative;
        margin-left: 5px;
        margin-right: 20px;

        @include eq-mobile {
          // span
          // {
          //     display: none;
          // }
        }

        .icon {
          position: absolute;
          width: 22px;
          height: 22px;
          img {
            width: 22px;
            height: 22px;
            transition: transform 0.25s ease-in-out;
          }
        }
      }
    }

    .dropdownMenuProfile {
      position: fixed;
      background: $darkblue;
      color: $white;
      visibility: hidden;
      opacity: 0;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
      z-index: 99;
      transition: all 0.5s ease-in-out;

      @include eq-mobile {
        left: 0;
        width: 100%;
        top: 70px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      @include at-desktop {
        top: 130px;
        border-radius: 10px;

        &::before {
          content: "";
          position: absolute;
          top: -8px;
          left: 20px;
          border-radius: 2px;
          background: $darkblue;
          width: 20px;
          height: 20px;
          transform: rotate(45deg);
        }
      }

      .profile-name {
        padding: 30px 30px 0;
        font-size: em(22px);
        font-weight: 600;
      }

      &__items {
        list-style-type: none;
        margin: 0;
        padding: 30px;
      }

      &__item {
        margin-bottom: 10px;
        .label {
          font-size: em(14px);
          &.first {
            text-transform: uppercase;
            font-weight: 600;
            color: $yellow;
          }

          &.logout {
            text-transform: uppercase;
            font-weight: 400;
            // margin-bottom: 40px;
            @include flexbox();
            @include align-items(center);
            // @include justify-content(flex-end);
            a {
              text-decoration: none;
              color: $white;
            }
          }

          // .hide-logout
          // {
          //     display: none;
          // }

          .icon-logout {
            margin-right: 10px;
          }
        }
      }

      .profile__submenu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $lightGrey;

        li {
          line-height: em(20px, 16px) !important;
          margin-bottom: 8px;
          font-size: 16px;
          a {
            color: $lightGrey;
            text-decoration: none;
            font-size: em(16px) !important;
            line-height: em(14px, 16px) !important;
            transition: color 0.5s ease-in-out;

            &:hover {
              color: $yellow;
            }
          }
        }
      }
    }

    &.open {
      .profile__text {
        .icon {
          img {
            transform: rotate(180deg);
          }
        }
      }

      .dropdownMenuProfile {
        visibility: visible;
        opacity: 1;
      }
    }

    &.insideMainMenu {
      position: relative;
      width: 100%;
      display: block;
      padding-bottom: 30px;
      padding-top: 30px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background: $darkblue;
        width: 100vw;
        height: 100%;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
          0 6px 6px rgba(0, 0, 0, 0.23);
      }

      .dropdownMenuProfile {
        position: relative;
        margin-top: 10px;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        top: 10px;
        background: transparent;

        &::before {
          display: none;
        }

        &__items {
          padding: 0;
        }
      }
    }
  }
}

.nhstudentmenu {
  .mainNavigationMenu__user {
    display: none;
  }
}

// Dropdown menu Header
.mainDropdownMenu {
  position: fixed;
  width: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;

  transition: opacity 0.5s ease-in-out;

  @include at-desktop {
    top: 160px;
    // height: calc(100vh - 200px);
  }

  &__wrapper {
    max-width: 1280px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    // background: $white;
    background: $mediumBlue;
    overflow-y: scroll;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    padding: 60px 20px;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &__headroom {
    left: 0;
  }

  &__items {
    column-count: 3;
    list-style-type: none;
  }

  &__item {
    // color: $dark;
    color: $white;
    transition: all 0.25s ease-in-out;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    padding: 0 20px;
    -webkit-column-break-inside: avoid;
    &:hover {
      // color: $mediumBlue;
      color: $yellow;
      a {
        // color: $mediumBlue;
        color: $yellow;
        text-decoration: none;
      }
    }
    a {
      text-decoration: none;
      // color: $dark;
      color: $white;
      transition: color 0.5s ease-in-out;
    }
  }

  .divider {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
    width: 100%;
    background: $lightGrey;
  }
}
