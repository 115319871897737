.card-body-inAccordion{
    padding: 26px 12px !important
}
.collapse-header-inAccordion{
    padding: 0px 12px !important;
}
.collapse-header-inAccordion::before{
    margin-top:20px !important;
}

.container{
    max-width: 1280px !important;
}