@import "../../../scss/helpers/resolutions";
@import "../../../scss//common/colors";
@import "../../../scss/helpers/mixins";

.mainNavigationMenu {
  position: relative;
  padding-top: 40px;

  &__user {
    .user {
      &__link {
        color: $white;
        text-decoration: none;
        font-size: em(16px);
        @include flexbox();
        @include align-items(center);
      }

      &__icon {
        width: 45px;
        height: 45px;
        svg {
          width: 100%;
          height: auto;
        }
      }

      &__text {
        margin-left: 5px;
      }
    }
  }

  &__primary {
    .menuPrimary {
      &__items {
        list-style-type: none;
        margin: 0;
        padding: 0;

        @include eq-mobile {
          margin-top: 20px;
        }

        @include eq-tablet {
          margin-top: 20px;
        }

        @include at-desktop {
          margin-top: 20px;
        }
      }
      &__item {
        margin-bottom: 10px;

        &__text {
          position: relative;
          text-decoration: none;
          color: $white;
          font-weight: 600;
          font-size: em(18px);

          // @include eq-mobile
          // {
          // }

          // @include at-tablet
          // {
          //     font-size: em(22px);
          // }

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 0;
            background: $lightGrey;
            transition: width 0.5s ease-in-out;
          }

          &:hover {
            color: $white;

            &::after {
              width: 100%;
            }
          }
        }

        &__dropdown {
          position: relative;
          text-decoration: none;
          color: $white;
          font-weight: 600;
          font-size: em(18px);

          .title {
            position: relative;
            cursor: pointer;
            display: inline-flex;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              height: 1px;
              width: 0;
              background: $lightGrey;
              transition: width 0.5s ease-in-out;
            }

            &:hover {
              color: $white;

              &::after {
                width: 100%;
              }
            }
            .icon {
              width: auto;
              height: auto;
              margin-left: 10px;
              transition: transform 0.5s ease-in-out;
            }

            &.open {
              .icon {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &__submenu {
        position: relative;
        list-style-type: none;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0;

        // @include eq-mobile
        // {
        //     padding-left: 20px;
        //     padding-right: 20px;
        // }

        // @include eq-tablet
        // {
        //     padding-left: 20px;
        //     padding-right: 20px;
        // }

        // @include at-desktop
        // {
        //     padding-left: 30px;
        // }

        .divider {
          margin-top: 20px;
          margin-bottom: 20px;
          display: inline-block;
          width: 100%;
          height: 1px;
          background-color: $lightGrey;
        }

        li {
          position: relative;
          a {
            position: relative;
            display: inline-block;
            font-size: em(16px);
            font-weight: 300;
            margin-bottom: 10px;
            color: $white;
            text-decoration: none;

            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              height: 1px;
              width: 0;
              background: $lightGrey;
              transition: width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            &:hover {
              color: $white;

              &::after {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .accordion {
    &__content {
      overflow: hidden;
      height: 0px;
      transition: height 0.5s ease-in-out;

      > [data-wrapper-height] {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.open
      {
        height: auto;
        overflow: auto;
      }
    }
  }

  &__secondary {
    .menuSecondary {
      &__items {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &__item {
        margin-bottom: 7px;

        a {
          position: relative;
          color: $white;
          text-decoration: none;
          text-transform: uppercase;
          display: inline-block;
          font-size: em(14px);

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: $lightGrey;
            transition: width 0.5s ease-in-out;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__social {
    margin-top: 40px;

    .social {
      &__items {
        @include flexbox();
        @include align-items(center);
        margin: 0;
        padding: 0;
        list-style-type: none;
      }

      &__item {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }

        a {
          width: 40px;
          height: 40px;
          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          @include flex-wrap(wrap);

          svg {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  &__service {
    .menuService {
      &__items {
        margin: 0;
        padding: 0;
        list-style-type: none;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &__item {
        @include eq-mobile {
          margin-bottom: 5px;
        }

        @include eq-tablet {
          margin-bottom: 5px;
        }

        // @include at-desktop
        // {
        //     margin-left: 20px;
        //     &:first-child
        //     {
        //         margin-left: 0px;
        //     }
        // }
        a {
          position: relative;
          color: $white;
          text-decoration: none;
          display: inline-block;
          font-size: em(14px);

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background: $lightGrey;
            transition: width 0.5s ease-in-out;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
