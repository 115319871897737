#company-section {
  background-image: URL(/img/mnemo/home.jpg);
  min-height: 500px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 25px;
  overflow: hidden;
}

#slider-section {
  margin: 0 !important;
  padding: 0 !important;
}

#other-section {
  margin-bottom: 60px;
  margin-left: 10px;
  margin-right: 10px;
}

#badge-section {
  margin-left: 10px;
  margin-right: 10px;
}

#cinquepermille {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
  padding: 0 20px;
}

#cinquepermille a {
  display: block;
}

#cinquepermille a img {
  width: 100%;
  height: auto;
  max-width: 1024px;
  margin: 0 auto;
}

#cinquepermille_leoni {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #23596c;
  color: #ffffff;
  border-radius: 20px;
  text-align: center;
}

h2.cinquepermille_leoni_title {
  color: #ffffff !important;
}

.cinquepermille_leoni_description {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
}

.cinquepermille_leoni_image {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cinquepermille_leoni_image img {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.cinquepermille_leoni_info {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: left;
}

h3.cinquepermille_leoni_info_title {
  color: #ffffff !important;
}

ul.cinquepermille_leoni_list {
  margin-top: 20px;
  margin-bottom: 20px;
}
