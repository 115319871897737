@import "../../scss/helpers/resolutions";
@import "../../scss//common/colors";
@import "../../scss/helpers/mixins";

#news-section {
  overflow: hidden;
  background-color: #f7f7f7;
}

.blogHomeBox {
  // background: red;
  margin: 0 auto;
  overflow: hidden;

  @include eq-mobile {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @include at-desktop {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  &__wrapper {
    padding: 20px;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    &__row {
      padding: 0px;
    }
  }

  @media screen and (min-width: 768px) {
    &__row {
      padding: 0 48px;
    }
  }

  @media screen and (max-width: 768px) {
    &__title {
   
      text-align: left;
      padding-left: 0px;
      padding-bottom: 12px;    
    }
  }

  @media screen and (min-width: 768px) {
    &__title {
   
      text-align: left;
      padding-left: 48px;
      padding-bottom: 12px;        
    }
  }


  .swiper-container {
    @include eq-mobile {
      padding: 0px 20px 40px;
    }
    @include at-desktop {
      padding: 10px 10px 40px;
    }
  }
}
